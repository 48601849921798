import React, { PureComponent } from 'react';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Carousel from '../../components/Carousel';
import Dropdown from '../../components/Dropdown';
import Leaderboard from '../../components/Leaderboard';
import Leaderboard2022Data from '../../data/sailing_leaderboard_2022.json';
import Leaderboard2023Data from '../../data/sailing_leaderboard_2023.json';
import Leaderboard2024Data from '../../data/sailing_leaderboard_2024_Hal.json';
import WCMBC_Logo from '../../assets/wcmbc_logo.svg';
import './styles.scss';

class Sailing extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            LeaderboardData: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        // Download a resource with cache busting, but update the HTTP
        // cache with the downloaded resource.
        fetch("data/sailing_leaderboard.json", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            cache: 'reload'
        })
            .then((response) => response.json())
            .then((json) => this.setState({ LeaderboardData: json }));
    }

    render() {
        return (
            <div id="sailing-page">
                <Navbar selected="sailing" />
                <div id="sailing-page-content">
                    <Header />
                    <div className="text-container">
                        <img src={WCMBC_Logo} alt="wcmbc logo" className='logo' />
                        <h2>Sailing</h2>
                        <p>
                            'Yachts' include both racing yachts, but also can mean wind-driven vessels -
                            in the latter case they may well be 'scale models'
                        </p>
                        <p>
                            Many racing yachts are of 'class' designs, and may be certified for compliance
                            with the class specifications.  An example of these are the 'International One Metre'
                            (IOM) class.
                        </p>
                        <p>
                            Other designs are also used for model yachting, many of which are used by the Club
                            members.
                        </p>
                        <p>
                            The club enjoys relaxed competition, provides camaraderie, sportsmanship and helps
                            build expertise. At present, there is no restriction on the classes sailed in regattas.
                        </p>
                        <p>
                            As a result, extreme competitiveness is not our objective!
                        </p>
                    </div>
                    <Carousel
                        showStatus={false}
                        showThumbs={false}
                        slides={[
                            {
                                image: require('../../assets/Carousels/Sailing/sailing_0.jpg'),
                                text: "Course layout"
                            },
                            {
                                image: require('../../assets/Carousels/Sailing/sailing_1.jpg'),
                                text: "Sailors taking aim"
                            },
                            {
                                image: require('../../assets/Carousels/Sailing/sailing_2.jpg'),
                                text: "Race start!"
                            },
                            {
                                image: require('../../assets/Carousels/Sailing/sailing_3.jpg'),
                                text: "First bouy wholeshot challenge"
                            },
                            {
                                image: require('../../assets/Carousels/Sailing/sailing_4.jpg'),
                                text: "Coming around the bend"
                            },
                            {
                                image: require('../../assets/Carousels/Sailing/sailing_5.jpg'),
                                text: "Waiting for that breeze..."
                            },
                        ]}
                    />
                    <Dropdown
                        title="Points System (2025)"
                        content={(
                            <div>
                                <p>
                                    We use <a href='https://www.halsraceresults.com/'>Hal's Race Results</a> software
                                    to generate race results.
                                </p>
                                <p>
                                    However, total points are hidden until the end of the season.
                                    The reason for this is that there will be a certain number of races discarded.
                                    This is to reduce the impact of missing a couple races throughout the season
                                    and bring the overall competition a bit closer between positions.
                                </p>
                                <p>
                                    The leaderboard below is NOT the official leaderboard!
                                    It is just meant to be an alternative leaderboard for those
                                    who prefer a simple scoring system without handicaps.
                                </p>
                                <p>
                                    The scoring system used for the leaderboard below is the same as the
                                    MotoGP scoring system:
                                </p>
                                <ul>
                                    <li>1st     - 25 points</li>
                                    <li>2nd     - 20 points</li>
                                    <li>3rd     - 15 points</li>
                                    <li>4th     - 13 points</li>
                                    <li>5th     - 11 points</li>
                                    <li>6th     - 10 points</li>
                                    <li>7th     - 9 points</li>
                                    <li>8th     - 8 points</li>
                                    <li>9th     - 7 points</li>
                                    <li>10th    - 6 points</li>
                                    <li>11th    - 5 points</li>
                                    <li>12th    - 4 points</li>
                                    <li>13th    - 3 points</li>
                                    <li>14th    - 2 points</li>
                                    <li>15th    - 1 points</li>
                                    <li>DNF     - 0 points</li>
                                </ul>
                            </div>
                        )}
                    />
                    {this.state.LeaderboardData ?
                        <Leaderboard
                            title={"Leaderboard (2025) (last updated: " + this.state.LeaderboardData.lastUpdated + ")"}
                            data={this.state.LeaderboardData.leaderboard_noHcap}
                        />
                        :
                        <div>Loading...</div>
                    }
                    <Dropdown
                        title="Points System 2024"
                        hidden={true}
                        content={(
                            <div>
                                <p>
                                    We use <a href='https://www.halsraceresults.com/'>Hal's Race Results</a> software
                                    to generate regatta results.
                                </p>
                                <p>
                                    The way it works is as follows:
                                </p>
                                <ul>
                                    <li>A stopwatch is started at the start of the regatta.</li>
                                    <li>Each boats finishing time is recorded.</li>
                                    <li>The times are entered into Hal's software.</li>
                                    <li>Hal's calculates results including handicaps.</li>
                                    <li>The final results are exported from Hal's software into an excel spreadsheet.</li>
                                    <li>The total points for the year are tallied up and displayed in the Leaderboard above.</li>
                                </ul>
                            </div>
                        )}
                    />
                    <hr />
                    <Leaderboard
                        title="Leaderboard (2024)"
                        data={Leaderboard2024Data.leaderboard}
                        hidden={true}
                    />
                    <hr />
                    <Leaderboard
                        title="Leaderboard (2023)"
                        data={Leaderboard2023Data}
                        hidden={true}
                    />
                    <hr />
                    <Leaderboard
                        title="Leaderboard (2022)"
                        data={Leaderboard2022Data}
                        hidden={true}
                    />
                </div>
                <Footer />
            </div>
        );
    }
}

export default Sailing;