import React, { PureComponent } from 'react';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import Carousel from '../../components/Carousel';
import Footer from '../../components/Footer';
import Leaderboard from '../../components/Leaderboard';
import Leaderboard2023Data from '../../data/speedboats_leaderboard_2023.json';
import Leaderboard2024Data from '../../data/speedboats_leaderboard_2024.json';
import PointsSystemData from '../../data/pointsSystem_2023.json';
import Motors from '../../assets/Motor_Sizes.jpg';
import Batteries from '../../assets/Batteries.jpg';
import { Link } from 'react-router-dom';
import Dropdown from '../../components/Dropdown';
import WCMBC_Logo from '../../assets/wcmbc_logo.svg';
import './styles.scss';

class Speedboats extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            LeaderboardData: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);


        // Download a resource with cache busting, but update the HTTP
        // cache with the downloaded resource.
        fetch("data/speedboats_leaderboard.json", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            cache: 'reload'

        })
            .then((response) => response.json())
            .then((json) => this.setState({ LeaderboardData: json }));
    }

    render() {
        return (
            <div id="speedboats-page">
                <Navbar selected="speedboats" />
                <div id="speedboats-page-content">
                    <Header />
                    <div className="text-container">
                        <img src={WCMBC_Logo} alt="wcmbc logo" className='logo' />
                        <h2>Speedboats</h2>
                        <p>
                            The speedboats division has been less active over the years - however,
                            the Club would like to see new interest in this aspect of the hobby.
                        </p>
                        <p>
                            A restriction, however, is that <strong>only electric-driven</strong> models are allowed,
                            and noise must be within acceptable limits. These considerations are to
                            ensure that surrounding residential homes are not subjected to any nuisance!
                            However, this is in respect of the Sonstraal Dam venue; there are
                            possibilities of other venues.
                        </p>
                    </div>
                    <div className="text-container">
                        <h2>Classes</h2>
                        <p>
                            Classes currently depend on whatever boats are racing on the day...
                        </p>
                        <p>
                            The first race is usually an OPEN class race.
                            Any boat can race in this class.
                            This is generally the "slow" race with older boats.
                            We encourage any first timers or novice racers to join
                            this class for a pressure free fun filled race.
                        </p>
                        <p>
                            Many of our members have been buying newer boats
                            in an attempt to make the speedboats division a bit more
                            interesting. These newer boats are generally MUCH faster
                            than the older boats within the club.
                            So we have put them into a class of their own as the second race.
                            The PRO class is where the real speed is at.
                            Here you can run your brushless motor with up to 4S LiPo's.
                            Most of the members are using Vector SR80's, but the class is
                            NOT limited to that hull.
                            This class is set to expand into 3 sub-classes - 2S, 3S and 4S.
                            This should happen once enough of us have those batteries.
                        </p>
                        <hr />
                        <div className='class-title'>OPEN Class:</div>
                        <div className='icons'>
                            <img src={Motors} alt='motors' className='class-icon' />
                            <img src={Batteries} alt='batteries' className='class-icon' />
                        </div>
                        <ol className='class-list'>
                            <li>Any hull</li>
                            <li>Any (ELECTRIC!) Motor</li>
                            <li>Any Battery</li>
                        </ol>
                        <hr />
                        <div className='class-title'>PRO Class:</div>
                        <ol className='class-list'>
                            <li>Brushless Motor</li>
                            <li>2-4S LiPo</li>
                        </ol>
                    </div>
                    <div className='text-container'>
                        <h2>Build Help</h2>
                        <p>
                            Here are a couple YouTube channels with some really interesting
                            info for RC speedboats: <br />
                        </p>
                        <ul>
                            <li>
                                <a href='https://www.youtube.com/@IRONCLADRC' target="_blank" rel="noreferrer">IronCladRC</a>
                            </li>
                            <li>
                                <a href='https://www.youtube.com/@RCexplained' target="_blank" rel="noreferrer">RCExplained</a>
                            </li>
                        </ul>
                        <p>
                            Below is a link to a YouTube video of an in-depth explanation
                            on how to pick all the parts to build a RC speed boat:
                        </p>
                        <a href='https://youtu.be/2tuvoAfPMYU' target="_blank" rel="noreferrer">YouTube - Selecting a motor, battery and prop</a>
                        <p>
                            Here's a calculator based off the info in the video:
                        </p>
                        <Link to="/calc">Calculator - Selecting a motor, battery and prop</Link>
                    </div>
                    <Carousel
                        showStatus={false}
                        showThumbs={false}
                        slides={[
                            {
                                image: require('../../assets/Carousels/Speedboats/speedboats_0.jpg'),
                                text: "Vector SR80 getting some speed"
                            },
                            {
                                image: require('../../assets/Carousels/Speedboats/speedboats_1.jpg'),
                                text: "Vector SR80 close up"
                            },
                            {
                                image: require('../../assets/Carousels/Speedboats/speedboats_2.jpg'),
                                text: "Flatty - Top and bottom hull"
                            },
                            {
                                image: require('../../assets/Carousels/Speedboats/speedboats_3.jpg'),
                                text: "Flatty - Component placement"
                            },
                            {
                                image: require('../../assets/Carousels/Speedboats/speedboats_4.jpg'),
                                text: "Ready for race day!"
                            },
                            {
                                image: require('../../assets/Carousels/Speedboats/speedboats_5.jpg'),
                                text: "Antons mini jet boat"
                            },
                        ]}
                    />
                    <Dropdown
                        title="Points System (2025)"
                        content={(
                            <div>
                                <p>
                                    We use <a href='https://www.halsraceresults.com/'>Hal's Race Results</a> software
                                    to generate race results.
                                </p>
                                <p>
                                    However, total points are hidden until the end of the season.
                                    The reason for this is that there will be a certain number of races discarded.
                                    This is to reduce the impact of missing a couple races throughout the season
                                    and bring the overall competition a bit closer between positions.
                                </p>
                                <p>
                                    The leaderboard below is NOT the official leaderboard!
                                    It is just meant to be an alternative leaderboard for those 
                                    who prefer a simple scoring system without handicaps.
                                </p>
                                <p>
                                    The scoring system used for the leaderboard below is the same as the 
                                    MotoGP scoring system:
                                </p>
                                <ul>
                                    <li>1st     - 25 points</li>
                                    <li>2nd     - 20 points</li>
                                    <li>3rd     - 15 points</li>
                                    <li>4th     - 13 points</li>
                                    <li>5th     - 11 points</li>
                                    <li>6th     - 10 points</li>
                                    <li>7th     - 9 points</li>
                                    <li>8th     - 8 points</li>
                                    <li>9th     - 7 points</li>
                                    <li>10th    - 6 points</li>
                                    <li>11th    - 5 points</li>
                                    <li>12th    - 4 points</li>
                                    <li>13th    - 3 points</li>
                                    <li>14th    - 2 points</li>
                                    <li>15th    - 1 points</li>
                                    <li>DNF     - 0 points</li>
                                </ul>
                            </div>
                        )}
                    />
                    {this.state.LeaderboardData ?
                        <Leaderboard
                            title={"Leaderboard (2025) (Last Updated: " + this.state.LeaderboardData.lastUpdated + ")"}
                            data={this.state.LeaderboardData.leaderboard_noHcap}
                        />
                        :
                        <div>Loading...</div>
                    }
                    <Dropdown
                        title="Points System (2024)"
                        hidden={true}
                        content={(
                            <div>
                                <p>
                                    We use <a href='https://www.halsraceresults.com/'>Hal's Race Results</a> software
                                    to generate race results.
                                </p>
                                <p>
                                    The way it works is as follows:
                                </p>
                                <ul>
                                    <li>A stopwatch is started at the start of the race.</li>
                                    <li>Each boats finishing time is recorded.</li>
                                    <li>The times are entered into Hal's software.</li>
                                    <li>Hal's calculates results including handicaps.</li>
                                    <li>The final results are exported from Hal's software into an excel spreadsheet.</li>
                                    <li>The excel sheet for each race is available for download from the Race Results section above.</li>
                                    <li>The total points for the year are tallied up and displayed in the Leaderboard above.</li>
                                </ul>
                            </div>
                        )}
                    />
                    <hr />
                    <Leaderboard
                        title="Leaderboard (2024)"
                        data={Leaderboard2024Data.leaderboard}
                        hidden={true}
                    />
                    <Leaderboard
                        title="Points System (2023)"
                        data={PointsSystemData}
                        hidden={true}
                    />
                    <Leaderboard
                        title="Leaderboard (2023)"
                        data={Leaderboard2023Data}
                        hidden={true}
                    />
                </div>
                <Footer />
            </div>
        );
    }
}

export default Speedboats;